



































import {
  computed,
  defineComponent,
  ref,
  PropType,
  useContext
} from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { SfButton } from '@storefront-ui/vue';
import { useKECart } from '@vue-storefront/novulo';
import { getCurrencyCode, getBrowserDetails } from '~/helpers/utils';

export default defineComponent({
  name: 'AddToCartButton',
  components: {
    SfButton
  },
  props: {
    list: { type: String, default: null },
    category: { type: String, default: null },
    brand: { type: String, default: null },
    spliId: { type: Number, required: true, default: null },
    name: { type: String, default: null },
    price: { type: Number, default: null },
    sku: { type: String, default: null },
    combidealIds: { type: Array, default: null },
    quantity: { type: Number, default: 1 },
    small: { type: Boolean, default: false },
    iconOnly: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: false },
    text: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    isOccasion: { type: Boolean, default: false },
    addAnimationType: {
      type: String as PropType<'default' | 'icon-only'>,
      default: 'default'
    }
  },
  setup(props) {
    const context = useContext();
    const vsfContext = useVSFContext();

    const ADD_TIMEOUT_MS = 2000;
    const isAdding = ref(false);
    const contentContainerElement = ref(null);
    const { addToCart, cartItems } = useKECart();

    const handleButtonClick = () => {
      if (isAdding.value) return;
      // set button width to prevent content from shifting
      contentContainerElement.value.style.width = `${contentContainerElement.value.offsetWidth}px`;
      isAdding.value = true;
      addToCart({
        list: props.list,
        category: props.category,
        brand: props.brand,
        spliId: props.spliId,
        name: props.name,
        price: props.price,
        sku: props.sku,
        combidealIds: props.combidealIds,
        quantity: props.quantity,
        currency: getCurrencyCode({ vsfContext }),
        browserDetails: getBrowserDetails({ vsfContext, i18n: context.i18n })
      });
      setTimeout(() => {
        isAdding.value = false;
        // reset button width
        contentContainerElement.value.style.width = 'auto';
      }, ADD_TIMEOUT_MS);
    };

    const isButtonDisabled = computed(
      () =>
        props.isDisabled ||
        (cartItems.value?.find((item) => item.spli_id === props.spliId) &&
          props.isOccasion)
    );

    return {
      cartItems,
      isAdding,
      contentContainerElement,
      handleButtonClick,
      isButtonDisabled
    };
  }
});
