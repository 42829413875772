















import { SfBadge } from '@storefront-ui/vue';
import { defineComponent, PropType } from '@nuxtjs/composition-api';

export type LabelVariantType =
  | 'available'
  | 'last-item'
  | 'not-available'
  | 'promotion'
  | 'icon'
  | 'award'
  | 'free'
  | 'soon-available'
  | 'success'
  | 'error'
  | 'store-closed'
  | 'black-friday';

export type LabelSize = 'large' | 'small';

export default defineComponent({
  name: 'KELabel',
  components: {
    SfBadge
  },
  props: {
    textColor: { type: String, default: '#00a984' },
    backgroundColor: { type: String, default: '#cceee6' },
    variant: {
      type: String as PropType<LabelVariantType>,
      required: true,
      default: 'available'
    },
    size: {
      type: String as PropType<LabelSize>,
      default: 'large'
    }
  }
});
