




























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    leftColor: {
      type: String,
      default: '#FFD339'
    },
    rightColor: {
      type: String,
      default: '#DADADA'
    },
    leftCoverage: {
      type: Number,
      default: 1,
      validator: (value) => value >= 0 && value <= 1
    }
  }
});
