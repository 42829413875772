













import {
  defineComponent,
  PropType,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useWishlist } from '@vue-storefront/novulo';
import { getCurrencyCode, getBrowserDetails } from '~/helpers/utils';

type FavoriteButtonTypes = 'border' | 'no-border';

export default defineComponent({
  name: 'FavoriteButton',
  props: {
    variant: {
      type: String as PropType<FavoriteButtonTypes>,
      default: 'border'
    },
    spli_id: {
      type: Number,
      required: true,
      default: 0
    },
    sku: {
      type: String,
      required: true,
      default: ''
    },
    productUnavailable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const context = useContext();
    const vsfContext = useVSFContext();

    const { wishlist, toggleFavorite } = useWishlist();

    const buttonVariant = computed(() => {
      return props.variant === 'border' ? 'secondary' : 'tertiary';
    });

    const heartComponent = computed(() => {
      return props.variant === 'border'
        ? 'IconsHeartIcon24px'
        : 'IconsHeartIcon32px';
    });

    const isFavorite = computed(() =>
      Boolean(
        wishlist.value?.find(
          (wishlistItem) => wishlistItem.spli_id === props.spli_id
        )
      )
    );

    const isDisabled = computed(
      () => props.productUnavailable && !isFavorite.value
    );

    const heartClass = computed(() => {
      let heartClass = 'heart-icon';
      if (isFavorite.value) {
        heartClass += '--active';
      } else if (props.variant === 'no-border') {
        heartClass += '--no-border';
      }
      return heartClass;
    });

    const handleToggleFavorite = () => {
      toggleFavorite({
        spli: props.spli_id,
        sku: props.sku,
        currency: getCurrencyCode({ vsfContext }),
        browserDetails: getBrowserDetails({ vsfContext, i18n: context.i18n })
      });
    };

    return {
      buttonVariant,
      heartClass,
      heartComponent,
      isFavorite,
      isDisabled,
      handleToggleFavorite
    };
  }
});
