










































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { priceFormat, extractIntegerAndFormat } from '~/helpers/utils';

export default defineComponent({
  name: 'Price',
  props: {
    currentPrice: {
      type: Number,
      required: true,
      default: 0
    },
    advisedPrice: {
      type: Number,
      required: false,
      default: 0
    },
    cashDiscountPrice: {
      type: Number,
      default: 0
    },
    cashbackPrice: {
      type: Number,
      required: false,
      default: 0
    },
    priceAlignVCenter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const extractDecimal = (price: number) => {
      const fraction = price % 1;
      return fraction ? fraction.toFixed(2).substring(2) : '';
    };

    const showMSRP = computed(
      () =>
        props.advisedPrice && !(props.advisedPrice <= props.currentPrice * 1.01)
    );
    const showStrikethrough = computed(
      () =>
        props.cashDiscountPrice && props.cashDiscountPrice < props.currentPrice
    );
    const showCashbackPrice = computed(
      () =>
        props.cashbackPrice &&
        props.cashbackPrice <
          (showStrikethrough ? props.cashDiscountPrice : props.currentPrice)
    );

    const strikethroughPrice = computed(() => ({
      integer: extractIntegerAndFormat(props.currentPrice),
      fraction: extractDecimal(props.currentPrice)
    }));

    const actualPrice = computed(() => ({
      test: showStrikethrough.value
        ? props.cashDiscountPrice
        : props.currentPrice,
      integer: extractIntegerAndFormat(
        showStrikethrough.value ? props.cashDiscountPrice : props.currentPrice
      ),
      fraction: extractDecimal(
        showStrikethrough.value ? props.cashDiscountPrice : props.currentPrice
      )
    }));

    return {
      showMSRP,
      showStrikethrough,
      showCashbackPrice,
      strikethroughPrice,
      actualPrice,
      priceFormat
    };
  }
});
