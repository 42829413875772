












import { defineComponent } from '@nuxtjs/composition-api';
import StarIcon16px from '../icons/star/StarIcon16px.vue';

export default defineComponent({
  name: 'RatingStarsBar',
  components: {
    StarIcon16px
  },
  props: {
    score: {
      type: Number,
      required: false,
      validator: (value) => value >= 0 && value <= 5,
      default: 0
    }
  },
  setup(props) {
    const indexCoverage = (index: number) => {
      // vue for is 1 indexed so we need to subtract 1
      const zeroIndex = index - 1;
      if (zeroIndex < Math.floor(props.score)) return 1;
      if (zeroIndex >= Math.ceil(props.score)) return 0;
      // show half a star for any decimal value
      return 0.5;
    };

    return {
      indexCoverage
    };
  }
});
