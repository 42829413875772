

























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CartIcon24px',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
});
